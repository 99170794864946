import { ApolloQueryResult, gql, useApolloClient } from "@apollo/client";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, CircularProgress, IconButton, Link, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetDevicesBySiteId } from "../../../../../../Models/models";
import localized from "../../../../../../en.json";
import {
  CertificateLinkIcon,
  SearchIcon,
  WarningIcon,
} from "../../../../../../theme/Icons/IshIcons";
import { ConfirmationDialog } from "../../../../../../util/ConfirmationDialog";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../../../../util/SearchStyleUtil";
import { TableComponent } from "../../../../../../util/TableComponent";
import ShowSnackbar from "../../../../../CustomizedSnackbar/ShowSnackbar";
import { HeadCell } from "../../../EnergyAssets/AssetsTable/AssetsTable";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CertificateManagerDialog from "./CertificateManagerDialog";

export const UNASSIGN_DEVICE = gql`
  mutation ($deviceID: String!) {
    unAssignDeviceFromSite(deviceID: $deviceID)
  }
`;
export const GET_DEVICES_BY_SITE_ID = gql`
  query ($siteID: Int!) {
    getDevicesBySiteID(siteID: $siteID) {
      id
      name
      deviceStatus
      numberOfAssetsInDevice
      assetsInDevice {
        id
        name
      }
    }
  }
`;

interface Device {
  id: string;
  name: string;
  deviceStatus: string;
  numberOfAssetsInDevice: number;
  assetsInDevice: [
    {
      id: string;
      name: string;
    }
  ];
}

interface PropTypes {
  siteId: number;
}
const EdgeDevicesTable = (props: PropTypes) => {
  const { siteId } = props;
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();
  const [devices, setDevices] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);
  const [
    isUnassignDeviceConfirmationDialogOpen,
    setIsUnassignDeviceConfirmationDialogOpen,
  ] = useState<boolean>(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const handleCloseUnassignDialog = () => {
    setIsWarning(false);
    setIsUnassignDeviceConfirmationDialogOpen(false);
  };
  const [deviceIdToUnassign, setDeviceIdToUnassign] = useState<string | null>(
    null
  );
  const [openCertManagerDialog, setOpenCertManagerDialog] = useState(false);
  const [deviceIdForDialog,setDeviceIdForDialog] = useState<string>("");
  const [deviceName,setDeviceName] = useState<string>("");

  const renderDeviceStatus = (row: any) => {
    const isConfigured = row.deviceStatus === "Configured";
    return (
      <Box display="flex" alignItems="center">
      {isConfigured ? (
        <CheckCircleOutlineIcon
          sx={{
            color: "#007942 !important",
            width: "20px",
            height: "20px",
            marginRight: "4px",
          }}
        />
      ) : (
        <WarningIcon
          style={{
            marginRight: "4px",
            fontSize: 20,
            color: "red",
          }}
        />
      )}
      <Typography variant="body1" color="#5F5F5F">
        {isConfigured ? localized["configured"] : localized["non-configured"]}
      </Typography>
    </Box>
    );
  };

  const renderCertificateStatus = (row: any) => {
    return (
      <Box display="flex" alignItems="center">
        <WarningIcon
          style={{
            marginRight: "4px",
            fontSize: 20,
            color: "red",
          }}
        />
        {/* will be replaced by api response */}
        <Typography variant="body1" color="#5F5F5F" mr="4px">
          expired 3 days ago
        </Typography>
        <IconButton data-testid={"certificate-manager-" + row.id}
          sx={{ padding: "2px" }}
          onClick={() => {
            setOpenCertManagerDialog(true);
            setDeviceIdForDialog(row.id);
            setDeviceName(row.name);
          }}
        >
          <CertificateLinkIcon style={{ height: "20px", width: "20px" }} />
        </IconButton>
      </Box>
    );
  };
  const handleUnassignClick = (row: any) => {
    setDeviceIdToUnassign(row.id);
    setIsUnassignDeviceConfirmationDialogOpen(true);
    if (row.numberOfAssetsInDevice > 0) {
      setIsWarning(true);
      setTitle(localized["cannot-unassign-edge-device"]);
      setDescription(localized["unassign-edge-device-warning"]);
    } else {
      setTitle(localized["unassign-device"]);
      setDescription(localized["edge-device-unassign-confirmation"]);
    }
  };

  const renderUnassignView = (row: any) => {
    return (
        <Box sx={{ display: "flex", gap: "24px" }}>
          <Link
            underline="none"
            component="button"
            data-testid={`unassign-device-${row.id}`}
            onClick={() => {
              handleUnassignClick(row);
            }}
          >
            <Typography
              variant="overline"
              sx={{
                textTransform: "none",
                lineHeight: "22px",
                borderBottom: "1.5px solid #8A00E5",
              }}
            >
              {localized["unassign"]}
            </Typography>
          </Link>
          <Link
            underline="none"
            component="button"
            data-testid={`view-device-${row.id}`}
            onClick={() => {
              navigate(
                `/engineering/site/${siteId}/edgedevices/device/${row.id}/tab1`
              );
            }}
          >
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                lineHeight: "22px",
                borderBottom: "1.5px solid #8A00E5",
              }}
            >
              <Typography
                variant="overline"
                sx={{
                  textTransform: "none",
                  paddingRight: "8px",
                }}
              >
                {localized["view"]}
              </Typography>
              <ArrowForwardIosIcon sx={{ height: "10px", width: "10px" }} />
            </Box>
          </Link>
        </Box>
    );
  };
  const onUnassignDeviceConfirmHandler = () => {
    client
      .mutate({
        mutation: UNASSIGN_DEVICE,
        variables: {
          deviceID: deviceIdToUnassign,
        },
        fetchPolicy: "no-cache",
      })
      .then(() => {
        setIsUnassignDeviceConfirmationDialogOpen(false);
        ShowSnackbar(
          localized["edge-device-unassign-success"],
          true,
          enqueueSnackbar
        );
        getDevicesBySiteId();
      })
      .catch(() => {
        setIsUnassignDeviceConfirmationDialogOpen(false);
        ShowSnackbar(
          localized["edge-device-unassign-failed"],
          false,
          enqueueSnackbar
        );
      });
  };

  const renderEdgeDeviceConnected = (row: any) => {
    if (!row.assetsInDevice?.length) {
      return (
        <Link underline="none" component="button"  onClick={() => {
          navigate(
            `/engineering/site/${siteId}/asset-edgemapping`
          );
        }}>
          <Typography
            variant="overline"
            sx={{
              textTransform: "none",
              lineHeight: "22px",
              borderBottom: "1.5px solid #8A00E5",
            }}
          >
            {localized["map-energy-asset-link"]}
          </Typography>
        </Link>
      );
    }

    return row.assetsInDevice
      .map((device: { name: string }) => device.name)
      .join(", ");
  };

  const getDevicesBySiteId = async () => {
    setLoading(true);
    client
      .query({
        query: GET_DEVICES_BY_SITE_ID,
        variables: {
          siteID: siteId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: ApolloQueryResult<GetDevicesBySiteId>) => {
        const getDevicesBySiteId = response.data.getDevicesBySiteID;
        setDevices(getDevicesBySiteId);
      })
      .catch(() => {
        ShowSnackbar(
          localized["failed-to-fetch-devices"],
          false,
          enqueueSnackbar
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getDevicesBySiteId();
  }, [location, siteId]);

  const headCells: readonly HeadCell<any>[] = [
    { id: "id", label: "Device ID" },
    { id: "name", label: "Device Name" },
    { id: "deviceStatus", label: "Status" },
    { id: "energyAssetsConnected", label: "Energy Assets Connected" },
    { id: "certificateStatus", label: "Certificate Status" },
    { id: "unassignView", label: "" },
  ];

  useEffect(() => {
    const filtered = devices.filter(
      (devices) =>
        devices.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        devices.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        devices.deviceStatus.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredDevices(filtered);
  }, [searchQuery, devices]);

  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: "#0D0D0D", fontSize: "22px", marginBottom: "16px" }}
      >
        {localized["edge-devices"]}
      </Typography>

      <Search
        sx={{
          display: "flex",
          height: "44px",
          width: "240px !important",
          marginLeft: "0px !important",
          marginBottom: "16px",
        }}
      >
        <SearchIconWrapper>
          <SearchIcon
            sx={{ width: "24px", height: "24px", color: "#5D596E" }}
          />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search"
          inputProps={{ "aria-label": "search" }}
          sx={{ minWidth: "240px" }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Search>

      {loading ? (
        <Stack
          width="100%"
          height="calc(100vh - 465px)"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Stack>
      ) :( <TableComponent<any>
        tableData={filteredDevices}
        headCells={headCells}
        enableCheckboxSelection={false}
        customColumn={{
          deviceStatus: renderDeviceStatus,
          energyAssetsConnected: renderEdgeDeviceConnected,
          certificateStatus: renderCertificateStatus,
          unassignView: renderUnassignView,
        }}
      />)}
     
      {isUnassignDeviceConfirmationDialogOpen && (
        <ConfirmationDialog
          isDialogOpen={isUnassignDeviceConfirmationDialogOpen}
          handleCloseDialog={handleCloseUnassignDialog}
          title={title}
          description={description}
          leftButtonText={localized["cancel-btn"]}
          rightButtonText={localized["yes-unassign-device"]}
          leftButtonClickHandler={handleCloseUnassignDialog}
          rightButtonClickHandler={onUnassignDeviceConfirmHandler}
          isWarning={isWarning}
        />
      )}
      <CertificateManagerDialog open={openCertManagerDialog} handleClose={() => setOpenCertManagerDialog(false)} 
      deviceId={deviceIdForDialog} deviceName={deviceName} />
    </>
  );
};

export default EdgeDevicesTable;
