import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { ConfirmationDialog } from "../../../../../../util/ConfirmationDialog";
import localized from "../../../../../../en.json";
import { WarningIcon } from "../../../../../../theme/Icons/IshIcons";
import CloseIcon from "@mui/icons-material/Close";

interface CertificateManagerDialogProps {
  open: boolean;
  handleClose: () => void;
  deviceId: string;
  deviceName: string;
}
interface LabelValuePairProps {
  label: string;
  value: string;
}

const LabelValuePair: React.FC<LabelValuePairProps> = ({ label, value }) => {
  return (
    <Stack direction={"row"} mb="16px">
      <Typography variant="h5" sx={{ width: "120px" }}>
        {label}
      </Typography>
      <Typography variant="h5" sx={{ fontWeight: "400", pl: "8px" }}>
        {value}
      </Typography>
    </Stack>
  );
};

const CertificateManagerDialog: React.FC<CertificateManagerDialogProps> = ({
  open,
  handleClose,
  deviceId,
  deviceName,
}) => {
  const [openRevoke, setOpenRevoke] = React.useState(false);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        sx={{
          ".MuiDialog-paper ": {
            width: "522px !important",
          },
        }}
      >
        <DialogTitle
          id="certManager-dialog-title"
          sx={{
            p: "24px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "400", fontSize: "24px" }}>
            {localized["certificate-details-header"]}
          </Typography>
          <IconButton onClick={handleClose} sx={{ alignSelf: "center" }} data-testid="close-button">
            <CloseIcon
              style={{
                color: "#000",
                width: "32px",
                height: "32px",
              }}
            />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ p: "24px 0px 24px 0px" }}>
            <LabelValuePair
              label={localized["device-id-label"]}
              value={deviceId}
            />
            <LabelValuePair label={localized["device-name-label"]} value={deviceName} />
            <div style={{ display: "flex" }}>
              <LabelValuePair
                label={localized["expiration-date-label"]}
                value="10/22/22"
              />
              <WarningIcon
                style={{
                  margin: "2px 0px 0px 8px",
                  fontSize: 20,
                  color: "red",
                }}
              />
            </div>

            <Link
              component="button"
              onClick={() => {
                setOpenRevoke(true);
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400,
                  textTransform: "none",
                  lineHeight: "22px",
                }}
              >
                {localized["revoke-certificate"]}
              </Typography>
            </Link>
          </Box>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "24px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                alignItems: "center",
                borderRadius: "24px",
                width: "100%",
                height: "40px",
              }}
            >
              <ArrowOutwardIcon sx={{ mr: "8px" }} />
              <Typography variant="h5" sx={{ textTransform: "none" }}>
                {localized["certificate-manager-btn"]}
              </Typography>
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmationDialog
        isDialogOpen={openRevoke}
        handleCloseDialog={() => {
          setOpenRevoke(false);
        }}
        title={localized["revoke-certificate"]}
        description={localized["revoke-certificate-confirmation-msg"]}
        leftButtonText={localized["cancel-btn"]}
        rightButtonText={localized["revoke-yes-button"]}
        leftButtonClickHandler={() => {
          setOpenRevoke(false);
        }}
        rightButtonClickHandler={() => {}}
        isWarning={false}
      />
    </div>
  );
};

export default CertificateManagerDialog;
